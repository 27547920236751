<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="loading"
    :server-items-length="itemsCount"
    :options.sync="localTableOptions"
    :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
    must-sort
  >
  </v-data-table>
</template>

<script>
export default {
  name: 'ScheduleUsersList',
  props: {
    scheduleId: {
      type: Number,
    },
    tableOptions: {
      type: Object,
    },
  },
  watch: {
    scheduleId() {
      this.getItems();
    },
    tableOptions: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  computed: {
    localTableOptions: {
      get() {
        return this.tableOptions;
      },
      set(value) {
        this.$emit('updateOptions', value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      itemsCount: 0,
      headers: [
        {
          text: 'Name',
          value: 'userFullname',
          class: 'lightGrey',
        },
      ],
    };
  },
  methods: {
    getItems() {
      this.loading = true;
      const params = {
        scheduleId: this.scheduleId,
        options: this.tableOptions,
      };

      const url = 'get-attached-users-by-schedule?format=json';

      this.$axios.post(url, params).then((response) => {
        this.items = response.data.items;
        this.itemsCount = response.data.count;
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
