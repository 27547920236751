<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <SearchBar
          @search="onSearch"
          searchLabel="Search by schedule name..."
          ref="search"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <ScheduleUsersFilters
          :filters="filters"
          refs="filters"
          @applyFilters="applyFilters"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="exportItems()"
              v-on="on"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels
          v-model="expanded"
          transition="fade-transition"
          active-class="msaBlue white--text"
        >
          <v-expansion-panel
            v-for="(schedule, index) in schedules"
            :key="index"
            class="my-2"
          >
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(index)"
            >
              <v-row align="center">
                <v-col class="shrink">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col>
                  {{ schedule.name }}
                  <v-badge
                    class="ml-2"
                    :bordered="open"
                    :content="`${schedule.userCount}`"
                    offset-y="5"
                  ></v-badge>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ScheduleUsersList
                :schedule-id="schedule.id"
                :table-options="tableOptions"
                @updateOptions="
                  tableOptions = JSON.parse(JSON.stringify($event))
                "
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import ScheduleUsersFilters from '@/components/ScheduleUsers/ScheduleUsersFilters.vue';
import ScheduleUsersList from '@/components/ScheduleUsers/ScheduleUsersList.vue';
export default {
  name: 'ScheduleUsersReportPage',
  components: {
    SearchBar,
    ScheduleUsersFilters,
    ScheduleUsersList,
  },
  data() {
    return {
      schedules: [],
      filters: JSON.parse(
        JSON.stringify(this.$constants.SCHEDULE_USERS_FILTERS),
      ),
      expanded: undefined,
      expandedScheduleId: undefined,
      tableOptions: {
        sortBy: ['fullname'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
    };
  },
  watch: {
    expanded(val) {
      if (val === undefined) {
        this.expandedScheduleId = undefined;
      } else {
        this.expandedScheduleId = this.schedules[val].id;
      }
    },
  },
  methods: {
    getSchedules() {
      const url = 'get-schedules-by-company?format=json';
      const params = {
        loaderText: 'Loading...',
        filters: this.filters,
      };
      this.$axios.post(url, params).then((response) => {
        this.schedules = response.data;
        this.checkExpansion();
        this.$nextTick(() => {
          this.$refs.search.setSearch(this.filters.search);
        });
      });
    },
    onSearch(e) {
      this.filters.search = e;
      this.getSchedules();
    },
    // make sure expanded schedule is still expanded after search
    checkExpansion() {
      if (this.expandedScheduleId) {
        const index = this.schedules.findIndex(
          (s) => s.id === this.expandedScheduleId,
        );
        if (index > -1) {
          this.expanded = index;
        } else {
          this.tableOptions = {
            sortBy: ['fullname'],
            sortDesc: [false],
            page: this.$constants.PAGINATION.DEFAULT_PAGE,
            itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
          };
        }
      } else {
        this.tableOptions = {
          sortBy: ['fullname'],
          sortDesc: [false],
          page: this.$constants.PAGINATION.DEFAULT_PAGE,
          itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        };
      }
    },
    exportItems() {
      const params = {
        filters: this.filters,
        loaderText: 'Loading...',
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('export-schedule-users-by-company?format=json', params, options)
        .then((response) => {
          this.$helpers.downloadFile(
            response.data,
            'Schedule Users Export.xlsx',
          );
        });
    },
    applyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.getSchedules();
    },
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
  },
  mounted() {
    this.getSchedules();
  },
};
</script>

<style></style>
