<template>
  <v-container class="pa-0 mx-0">
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="filtersApplied ? 'orange' : 'msaBlue'"
              class="white--text"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filters</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn
                icon
                dark
                @click="
                  dialog = false;
                  setFilters();
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row dense>
            <v-col cols="12" class="pb-0 font-weight-bold">
              Schedule name
            </v-col>
            <v-col class="pb-2">
              <v-text-field
                v-model="localFilters.search"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="pb-0 font-weight-bold">
              Show deleted
            </v-col>
            <v-col class="pb-2">
              <v-btn-toggle
                v-model="localFilters.showDeleted"
                dense
                mandatory
                color="msaBlue"
                style="width: 100%"
              >
                <v-btn style="width: 50%" class="text-capitalize" :value="1">
                  Yes
                </v-btn>
                <v-btn style="width: 50%" class="text-capitalize" :value="0">
                  No
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text class="mr-5" @click="clearFilter()">
            Clear
          </v-btn>
          <v-btn color="msaBlue white--text" @click="applyFilter">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'ScheduleUsersFilters',
  props: {
    filters: {
      type: Object,
    },
  },
  computed: {
    filtersApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.SCHEDULE_USERS_FILTERS)
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.setFilters();
      }
    },
  },
  data() {
    return {
      dialog: false,
      localFilters: { ...this.$constants.SCHEDULE_USERS_FILTERS },
    };
  },
  methods: {
    applyFilter() {
      this.$emit('applyFilters', this.localFilters);
      this.dialog = false;
    },
    clearFilter() {
      this.localFilters = { ...this.$constants.SCHEDULE_USERS_FILTERS };
      this.applyFilter();
    },
    setFilters() {
      this.localFilters = { ...this.filters };
    },
  },
};
</script>
<style></style>
